import { AboutAnimation } from "../Assets/AssetsList";
import LottieAnimationCard from "../Components/LottieAnimationCard";
import Timeline from "../Components/TimeLine";
import Title from "../Components/Title";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import AboutMyPic from "../Assets/Vivek.jpeg";

const About = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        delay: 1 * 0.5,
      },
    },
  };
  return (
    <section id="about" className="flex flex-col items-center justify-center">
      {/* <Title text="About" styles="mb-10" /> */}
      {/* <LottieAnimationCard AnimationSrc={AboutAnimation} /> */}

      <motion.div
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={variants}
        ref={ref}
        className="my-7 w-full max-w-8xl"
      >
        <div class="flex flex-col md:flex-row items-center p-20  rounded-lg">
          <div className="w-full md:w-1/3 flex justify-center mb-5 md:mb-0">
            <img
              src={AboutMyPic}
              alt="Profile"
              className="w-60 h-60 md:w-80 md:h-80 object-cover shadow-lg rounded-full border-4 border-gray-700"
            />
          </div>
          <div className="w-full md:w-2/3 text-xl md:text-2xl text-black-300 leading-relaxed md:pl-6 dark:text-white">
            <span className="text-primary font-semibold block mb-2 text-black dark:text-white">
              Hello!
            </span>
            I am Vivek Patel, a skilled Full Stack Developer
            <span className="text-blue-400">(React.js & Node.js)</span> with
            over <span className="font-bold">7+ years of experience</span> in
            crafting dynamic and responsive web applications. I am currently
            working on freelance projects and enjoy creating elegant solutions
            for complex challenges. I ensure that the applications I build are
            top-notch, performant, and scalable. I am committed to innovation
            and continuous learning, which drives me to stay up-to-date with
            industry best practices.
            <span className="block mt-4">
              I would love to connect with you and explore opportunities to
              collaborate in the exciting world of Software development!
            </span>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default About;
