import React from 'react';
import Title from "../Components/Title";
import { motion } from "framer-motion";

const services = [
  {
    icon: '🌐',
    title: 'Responsive Web Design',
    description: 'Creating websites that work well on various devices and screen sizes. Use of frameworks like Bootstrap or custom media queries.',
  },
  {
    icon: '💻',
    title: 'Web Development',
    description: 'Crafting clean, semantic HTML. Styling with modern CSS, including Flexbox, Grid, and animations.',
  },
  {
    icon: '🛠️',
    title: 'JavaScript Development',
    description: 'Building interactive features using JavaScript. Knowledge of ES6+ features and asynchronous operations (e.g., promises, async/await).',
  },
  {
    icon: '⚛️',
    title: 'Front-End Frameworks/Libraries',
    description: 'Proficiency in React, NextJs for building dynamic user interfaces. Experience with state management libraries like Redux.',
  },
  {
    icon: '🔧',
    title: 'Maintenance and Support',
    description: 'Providing ongoing support, updates, and troubleshooting for web applications.',
  },
  {
    icon: '🔍',
    title: 'Accessibility',
    description: 'Ensuring websites meet accessibility standards. Knowledge of ARIA roles and best practices for accessible design.',
  },
];

const Myservice = () => {
  return (
    <motion.div
      className="flex justify-center items-center flex-col p-2 mt-10 mx-auto"
      id="projects"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
    >
      <Title text="My Services" />

      <div className="py-12 m-8 bg-gradient-to-r from-gray-100 to-gray-100 dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <motion.div
                key={index}
                className="text-center p-6 bg-white dark:bg-gray-800 rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300 transform hover:-translate-y-1"
                whileHover={{ scale: 1.05 }}
              >
                <div className="flex justify-center items-center text-6xl mb-4 text-teal-500 dark:text-teal-300">
                  {service.icon}
                </div>
                <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
                  {service.title}
                </h3>
                <p className="text-gray-600 text-xl dark:text-gray-400">
                  {service.description}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Myservice;
