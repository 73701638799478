import React from "react";
// import { GoGoal } from "react-icons/go";

const Vision = () => {
  return (
    // <div
    //   className="flex items-center justify-center h-screen bg-cover bg-center"
    // >
    <div className="text-center">
      <h1 className="text-5xl md:text-4xl font-bold text-gray-900 dark:text-white tracking-tight leading-snug drop-shadow-lg">
        Welcome to My Website!
      </h1>
      {/* <GoGoal /> */}
      <p className="text-lg md:text-xl text-gray-700 dark:text-gray-300 font-light mt-4 max-w-2xl mx-auto leading-relaxed tracking-wide">
        I am here to help you build your business with quality, sustainable, and
        economical solutions.
      </p>
    </div>

    //  </div>
  );
};

export default Vision;
