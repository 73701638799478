const NavItems = [
  "Home",
  "Services",
  "Projects",
  "About",
  "Contact",
  //  'Testimonial'
];

export default NavItems;
