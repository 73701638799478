import React, { useState, useRef } from "react";
import { motion, AnimatePresence, useInView } from "framer-motion";
import { ChevronLeft, ChevronRight, Quote } from "lucide-react";
import Title from "../Components/Title";

// Testimonials Data
const testimonials = [
  {
    name: "Ethan Harrison",
    title: "Chief Executive Officer at Vertex Dynamics",
    feedback:
      "Collaborating with Vivek was a transformative experience for our team. His mastery of React and Node.js enabled us to build a robust and scalable platform that outperformed our projections.",
    image: "/api/placeholder/150/150",
  },
  {
    name: "Sophia Martinez",
    title: "Senior Product Manager at Horizon Labs",
    feedback:
      "Vivek consistently delivers exceptional quality. His attention to detail and deep understanding of modern web technologies make him an invaluable partner in any development project.",
    image: "/api/placeholder/150/150",
  },
  {
    name: "Liam Chen",
    title: "Chief Technology Officer at Quantum FinTech",
    feedback:
      "Vivek's innovative approach to solving complex challenges is second to none. He has a unique ability to turn ideas into reality, making him a crucial asset to our success.",
    image: "/api/placeholder/150/150",
  },
];

const Testimonial = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Animation Variants
  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, delay: 0.5 },
    },
  };

  // Handlers for next and previous testimonials
  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  return (
    <motion.div className="p-2 mt-8 mx-auto" id="testimonial">
      {/* Title Section */}
      <motion.div
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={variants}
        ref={ref}
        className="text-center mb-10"
      >
        <Title text="Client Testimonials" />
        <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
          Discover what our satisfied clients have to say about our exceptional work and dedication to excellence.
        </p>
      </motion.div>

      {/* Testimonial Carousel */}
      <section className="relative max-w-4xl mx-auto">
        <div className="bg-white dark:bg-gray-800 shadow-2xl rounded-lg overflow-hidden p-8">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentIndex}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.5 }}
              className="flex flex-col items-center"
            >
              <Quote className="text-blue-500 w-16 h-16 mb-6 opacity-20" />
              <p className="text-xl md:text-2xl text-gray-700 dark:text-gray-200 text-center italic mb-8">
                "{testimonials[currentIndex].feedback}"
              </p>
              <div className="flex items-center">
                <div>
                  <h3 className="text-xl font-semibold text-gray-800 dark:text-white">
                    {testimonials[currentIndex].name}
                  </h3>
                  <p className="text-sm text-blue-500 dark:text-blue-400">
                    {testimonials[currentIndex].title}
                  </p>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>

          {/* Navigation Buttons */}
          <button
            onClick={prevTestimonial}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-1/2 bg-white dark:bg-gray-700 rounded-full p-3 shadow-lg hover:bg-blue-500 hover:text-white transition-colors duration-300"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
          <button
            onClick={nextTestimonial}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-1/2 bg-white dark:bg-gray-700 rounded-full p-3 shadow-lg hover:bg-blue-500 hover:text-white transition-colors duration-300"
          >
            <ChevronRight className="w-6 h-6" />
          </button>
        </div>

        {/* Dots Navigation */}
        <div className="flex justify-center mt-8">
          {testimonials.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`w-4 h-4 rounded-full mx-2 transition-all duration-300 ${
                index === currentIndex
                  ? "bg-blue-500 scale-125"
                  : "bg-gray-300 dark:bg-gray-600 hover:bg-blue-300 dark:hover:bg-blue-700"
              }`}
            />
          ))}
        </div>
      </section>
    </motion.div>
  );
};

export default Testimonial;
